import tests from '~/public/config-files/ab-tests.json';

export default defineNuxtPlugin((nuxtApp) => {
  if (window) {
    const choosenTests = [];
    //Check if test exist
    if (tests && tests.tests && tests.tests.length > 0) {
      tests.tests.forEach((test) => {
        const variant = ref(null);

        // check if the test is still valid (either a date is set and it is in the future or no date is set)
        if ((test.end && !isPastDate(test.end)) || !test.end) {
          //Check if a variant is already stored in the cookie
          const cookie = useCookie(`test_variant_${test.name}`, {
            expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
          });

          //Don't overwrite variant if it is already set
          if (cookie.value) {
            variant.value = cookie.value;
          } else {
            // Pick random variant from variants array in test
            const slots = test.variants.map((item) => item.name);
            const randomVariant = Math.floor(Math.random() * slots.length);
            variant.value = slots[randomVariant];
            cookie.value = variant.value;
          }

          // if a variant is picked, push the test to the choosenTests array for the dataLayer
          choosenTests.push({
            testName: test.name,
            variant: variant.value,
            id: `test_variant_${test.name}_${variant.value}`,
          });
        }
      });
    }
    //Push Tests to dataLayer
    window.dataLayer.push({
      event: 'pc_abtest',
      tests: choosenTests,
    });

    //Tests that are stored in a cookie but are no longer valid should be removed from cookies
    const removeOldTests = () => {
      const cookies = document.cookie.split(';');
      const cookieObjects = cookies.map((cookie) => {
        const [key, value] = cookie.split('=');
        // return key.replace('test_variant_', '').trim();
        return key;
      });
      const onlyTests = cookieObjects.filter((cookie) => {
        return cookie.includes('test_variant');
      });

      onlyTests.forEach((cookie) => {
        const trimmedName = cookie.replace('test_variant_', '').trim();
        const test = choosenTests.find((test) => {
          return trimmedName.includes(test.testName);
        });
        if (!test) {
          document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
      });
    };

    removeOldTests();
  }
});
