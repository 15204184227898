import defaults from '~/public/pwa-defaults/api-defaults.json';

export function getProduct({path}: {path: any}) {
  const app = useNuxtApp();
  const store = useDefaultStore();
  const shopwareStore = useShopwareStore();
  const {requestHeaders, requestPath} = utilRequests();
  const {getActiveAnimalSpecies} = useCategory();
  const router = useRouter();

  const fetchProduct = async () => {
    const pathMatch = path.path.substring(1);

    const productOptions = {
      method: 'POST',
      ...requestHeaders.value,
      body: {
        ...defaults.useCms,
        path: pathMatch,
      },
    };

    return await $fetch(`${requestPath}/pwa/page`, productOptions)
      .then(async (response: any) => {
        await store.setPage(response);
        await store.setCurrentProduct(response.product);

        // Check if you can set an acticve category
        const findMatchingCategory = () => {
          if (store.flatCategories.length) {
            const categories = JSON.parse(JSON.stringify(store.flatCategories));
            let findCategory = categories.find(
              (item: any) =>
                item.id ===
                (response.product.mainCategories?.at(0).categoryId ??
                  response.product.categoryTree.at(-1))
            );
            if (findCategory) {
              return findCategory;
            } else {
            }
          }
        };
        await store.setCurrentCategory(findMatchingCategory());

        // Check if you can set an acticve animal species
        getActiveAnimalSpecies(findMatchingCategory());

        // if (
        //   pathMatch &&
        //   response?.canonicalPathInfo &&
        //   pathMatch !== response?.canonicalPathInfo
        // ) {
        //   return navigateTo(response.canonicalPathInfo);
        // }
      })
      .catch((error: any) => {});
  };

  return {fetchProduct};
}

export function useProduct(product: any) {
  const app = useNuxtApp();
  const store = useDefaultStore();
  const shopwareStore = useShopwareStore();
  const {requestHeaders, requestPath} = utilRequests();
  const {getActiveAnimalSpecies} = useCategory();
  const router = useRouter();

  const parentProductId = computed(() => product.parentId);

  const availaibleOptions = computed(() => {
    if (store.getPage?.configurator?.length) {
      return store.getPage?.configurator;
    } else {
      return null;
    }
  });

  const findVariantForSelectedOptions = async (options) => {
    const filter = [
      {
        type: 'equals',
        field: 'parentId',
        value: parentProductId.value,
      },
      ...Object.values(options).map((id) => ({
        type: 'equals',
        field: 'optionIds',
        value: id,
      })),
    ];

    return await $fetch(`${requestPath}/product`, {
      method: 'POST',
      ...requestHeaders.value,
      body: {
        limit: 1,
        filter,
        includes: {
          product: ['id', 'translated', 'productNumber', 'seoUrls'],
          seo_url: ['seoPathInfo'],
        },
        associations: {
          seoUrls: {},
        },
      },
    }).then((response) => {
      return response?.elements?.[0];
    });
  };

  const getDeliveryTime = computed(() => {
    const deliveryUnit =
      app && product?.customFields?.deliveryTimes?.at(0)?.unit
        ? app?.$i18n?.t(
            `delivery.${product.customFields.deliveryTimes[0].unit.toLowerCase()}`
          )
        : 'Tage';

    if (
      product?.extensions?.productBrand?.at(0)?.brand?.customFields?.petcloud
        ?.overrideProductDeliveryTime &&
      product?.extensions?.productBrand?.at(0)?.brand?.customFields?.petcloud
        ?.earliestDeliveryTimeInDays &&
      product?.extensions?.productBrand?.at(0)?.brand?.customFields?.petcloud
        ?.latestDeliveryTimeInDays
    ) {
      return `${
        product?.extensions?.productBrand?.at(0)?.brand?.customFields?.petcloud
          ?.earliestDeliveryTimeInDays
      } - ${
        product?.extensions?.productBrand?.at(0)?.brand?.customFields?.petcloud
          ?.latestDeliveryTimeInDays
      } ${deliveryUnit}`;
    }

    if (product?.customFields?.deliveryTimes?.length) {
      return `${product.customFields.deliveryTimes[0].min} - ${product.customFields.deliveryTimes[0].max} ${deliveryUnit}`;
    }

    return '1 - 3 Tage';
  });

  return {findVariantForSelectedOptions, availaibleOptions, getDeliveryTime};
}
