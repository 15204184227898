export function checkHydration() {
  const temp = useTempStore();

  const isHydrating = computed(() => {
    return temp.getIsHydrating;
  });
  const finishHydrating = computed(() => {
    return !temp.getIsHydrating;
  });

  return {
    isHydrating,
    finishHydrating,
  };
}

export function useUtils() {
  const {sendNotification} = useNotification();

  const copyCode = (code) => {
    navigator.clipboard.writeText(code.toLowerCase());
    if (document != undefined) {
      document.cookie = `coupon=${code.toLowerCase()}`;
    }

    sendNotification({
      type: 'success',
      message: `Code ${code} kopiert`,
      icon: 'copy',
    });
  };

  return {
    copyCode,
  };
}

export function useComposables() {
  const store = useDefaultStore();

  const getBrandById = (id) => {
    const brand = store.getBrands.find((brand) => brand.id === id);
    if (brand) {
      return brand;
    } else return null;
  };

  const getBrandNameById = (id: Number, dash = false) => {
    const brand = getBrandById(id);

    if (brand && brand.name) {
      return brand.name + (dash ? ' - ' : '');
    } else {
      return '';
    }
  };

  return {
    getBrandById,
    getBrandNameById,
  };
}
