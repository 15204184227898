import revive_payload_client_vn5q75JjLk from "/app/node_modules/.pnpm/nuxt@3.13.2_rollup@4.21.3_sass@1.77.8_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AkChytPwFv from "/app/node_modules/.pnpm/nuxt@3.13.2_rollup@4.21.3_sass@1.77.8_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZZunPWpUII from "/app/node_modules/.pnpm/nuxt@3.13.2_rollup@4.21.3_sass@1.77.8_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_vCbJdUAdoq from "/app/node_modules/.pnpm/nuxt@3.13.2_rollup@4.21.3_sass@1.77.8_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vw60t4zckz from "/app/node_modules/.pnpm/nuxt@3.13.2_rollup@4.21.3_sass@1.77.8_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_OXJPV8nGOZ from "/app/node_modules/.pnpm/nuxt@3.13.2_rollup@4.21.3_sass@1.77.8_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_i7gBUQpWAW from "/app/node_modules/.pnpm/nuxt@3.13.2_rollup@4.21.3_sass@1.77.8_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_WDs8cMsseg from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.3_typescript@5.6.2_vue@3.5.6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_yflcLfErbO from "/app/node_modules/.pnpm/nuxt@3.13.2_rollup@4.21.3_sass@1.77.8_typescript@5.6.2_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_pAQaEMSnCL from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.13.2_rollup@4.21.3_vue@3.5.6/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_8rAQ1wjiwV from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.21.3_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ZDWvn13DfA from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.21.3_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_client_Y5fPRaJ2wn from "/app/node_modules/.pnpm/@sentry+nuxt@8.30.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_7stw4gxvcjaipk2guqbs4htuom/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/app/.nuxt/sentry-client-config.mjs";
import abtests_client_uUk0oXgKHp from "/app/plugins/abtests.client.js";
import bodyScrollLockDirective_PFVtPXiIP3 from "/app/plugins/bodyScrollLockDirective.ts";
import checkContextToken_client_KF1IOlmD0X from "/app/plugins/checkContextToken.client.js";
import clickOutsideDirective_JuSoe6vGeC from "/app/plugins/clickOutsideDirective.ts";
import error_handler_kEP5FliEXj from "/app/plugins/error-handler.ts";
import initApp_client_HB3je16GJC from "/app/plugins/initApp.client.ts";
import intersectionObserverDirective_hyi5PUXUBF from "/app/plugins/intersectionObserverDirective.ts";
import lottie_player_client_G22svxIhbR from "/app/plugins/lottie-player.client.ts";
import misc_client_XKFAtM619D from "/app/plugins/misc.client.ts";
import mitt_client_7uatExallr from "/app/plugins/mitt.client.ts";
import prefetchRoutes_client_suwW2YaEjO from "/app/plugins/prefetchRoutes.client.ts";
import scrollDirection_client_oUg7iS9aUv from "/app/plugins/scrollDirection.client.ts";
import settingDefaults_client_hSwNPT1S0t from "/app/plugins/settingDefaults.client.js";
export default [
  revive_payload_client_vn5q75JjLk,
  unhead_AkChytPwFv,
  router_ZZunPWpUII,
  payload_client_vCbJdUAdoq,
  navigation_repaint_client_vw60t4zckz,
  check_outdated_build_client_OXJPV8nGOZ,
  chunk_reload_client_i7gBUQpWAW,
  plugin_vue3_WDs8cMsseg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yflcLfErbO,
  plugin_pAQaEMSnCL,
  switch_locale_path_ssr_8rAQ1wjiwV,
  i18n_ZDWvn13DfA,
  sentry_client_Y5fPRaJ2wn,
  sentry_client_config_o34nk2sJbg,
  abtests_client_uUk0oXgKHp,
  bodyScrollLockDirective_PFVtPXiIP3,
  checkContextToken_client_KF1IOlmD0X,
  clickOutsideDirective_JuSoe6vGeC,
  error_handler_kEP5FliEXj,
  initApp_client_HB3je16GJC,
  intersectionObserverDirective_hyi5PUXUBF,
  lottie_player_client_G22svxIhbR,
  misc_client_XKFAtM619D,
  mitt_client_7uatExallr,
  prefetchRoutes_client_suwW2YaEjO,
  scrollDirection_client_oUg7iS9aUv,
  settingDefaults_client_hSwNPT1S0t
]